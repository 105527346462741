import { graphql, Link } from "gatsby";
import type { PageProps } from "gatsby";
import React from "react";
import Global from "../components/global";
import { Header } from "../components/header";
import HistoryList from "../components/history-list";
import Main from "../components/main";
import RecentTable from "../components/recent-table";
import Seo from "../components/seo";
import Wrapper from "../components/wrapper";
import { createMyDate, createTime, MyDate } from "../utils/datetimeutils";
import * as styles from "./index2.module.css";

export const Head = () => <Seo />;

type EntryWithObjects = {
  node: {
    name: string | null;
    namePrefix: string | null;
    nameSuffix: string | null;
    canceled: string | null;
    locationName: string | null;
    locationSubName: string | null;
    sources: string | null;

    dateObj?: MyDate;
    endDateObj?: MyDate;
    openTimeObj?: Date;
    startTimeObj?: Date;
    endTimeObj?: Date;
  };
};

const OldHome = ({ data }: PageProps<Queries.OldHomeQuery>) => {
  const HISTORY_LIST_MAX_NUM = 15;

  const taikaiList = data.allGoogleMetaSheet.edges.filter(({ node }) => {
    return node.taikaiId && node.nameOfficial && !node.outdated;
  });

  const entryList = data.allGoogleDataSheet.edges.filter(({ node }) => {
    return node.taikaiId && node.name;
  });

  return (
    <>
      <>
        <Global />
        <Header />
        <Main>
          <Wrapper classic={true}>
            <div className={styles.noticeBox}>
              <div>
                注意：こちらは旧版（PC向け）のページです。スマホ対応のページは <Link to="/">トップページ</Link> からご覧ください。
              </div>
            </div>

            <div id="top" className={styles.wrapper}>
              <div className={styles.leftPane}>
                <div className={styles.menuBox}>
                  <h2 className={styles.menuTitle}>ナビゲーション</h2>
                  <div className={styles.menuContentBox}>
                    <ul className={styles.menuUl}>
                      <li className={styles.menuSelectedLi}>ホーム（旧版）</li>
                      <li className={styles.menuLi}>
                        <Link to="/shishimai2/">獅子舞大会（旧版）</Link>
                      </li>
                      <li className={styles.menuLi}>
                        <Link to="/calendar/">カレンダー</Link>
                      </li>
                      <li className={styles.menuLi}>
                        <Link to="/links/">リンク</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className={styles.menuBox}>
                  <h2 className={styles.menuTitle}>更新履歴</h2>
                  <HistoryList
                    isClassic={true}
                    data={data.allGoogleHistorySheet.edges}
                    maxItems={HISTORY_LIST_MAX_NUM}
                    isAnchorLink={true}
                    isShishimai={false}
                  />
                </div>

                <div className={styles.menuBox}>
                  <h2 className={styles.menuTitle}>姉妹サイト</h2>
                  <ul className={styles.menuUl}>
                    <li className={styles.menuLi}>
                      <a href="https://myshishilist.wordpress.com/">お正月獅子舞一覧</a>
                    </li>
                  </ul>
                </div>

                <div className={styles.menuBox}>
                  <h2 className={styles.menuTitle}>X（旧ツイッター）</h2>
                  <ul className={styles.menuUl}>
                    <li className={styles.menuLi}>
                      <a href="https://x.com/tabigarasu24">@tabigarasu24</a> でやってます。
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.main}>
                <div>
                  <p className={styles.p}>
                    地域の郷土芸能を地区ホールなどで一度に公演する「郷土芸能発表会」「民俗芸能大会」といった催しが各地で開催されています。
                  </p>
                  <p className={styles.p}>
                    その地域の芸能を一度に見学できて便利なので、自分の所はもとより他の地域の発表会にもよく出かけたりするのですが、<br />
                    あちこち行きすぎてだんだん日程を把握しきれなくなってきました。<br />
                    しかも、このような催しは基本的にその地域の人向けなので、地区の広報などにしか情報が掲載されないことが多いです。<br />
                    結果として、「あっ、○×市の芸能大会ってそろそろだっけ？」と思って慌てて調べてみると、先週末に開催されてた、なんてこともしばしば…。
                  </p>
                  <p className={styles.p}>
                    ということできちんとスケジュールを管理できるよう、自分が知っている限りの発表会の日程を調べてリスト化しました。<br />
                    今後、定期的に更新できればいいなと思っています。
                  </p>
                  <p className={styles.p}>
                    ※ Googleカレンダーにイベントを記入して公開しています。<Link to="/calendar/">カレンダーのページ</Link> をご覧下さい。
                  </p>
                </div>

                <h2 className={styles.noteH2}>注意事項</h2>
                <ul className={styles.noteUl}>
                  <li className={styles.noteLi}>
                    私（サイト製作者）がよく見学する <b>祭囃子・神楽・獅子舞</b> を含む発表会のみリスト化しています。<br />
                    郷土芸能と聞いて思い浮かべる和太鼓や民謡や日本舞踊などの発表会は掲載していません。すみません。
                  </li>
                  <li className={styles.noteLi}>複数の団体の公演を一度に見ることが主眼ですので、一つの団体のみの公演・発表会は調査していません。</li>
                  <li className={styles.noteLi}>
                    <b>ここに掲載されている内容は間違っているかも知れません。</b>また、悪天候などの理由で中止になることもありますので、<br />
                    お出かけの際は主催者に内容をご確認下さい。
                  </li>
                  <li className={styles.noteLi}>古い情報はリンク切れになっているかも知れませんが、どうしようもないのでご了承下さい。</li>
                  <li className={styles.noteLi}>
                    このサイトはBeta版です。サイト製作者の多忙<span style={{ fontSize: "smaller" }}>
                      （仕事が、ではなく、祭り見物で、かも知れませんが -_-;）
                    </span>やその他の<br />
                    理由<span style={{ fontSize: "smaller" }}>（飽きたとか…）</span>により、予告することなく更新が止まる可能性があります。ご了承下さい。
                  </li>
                </ul>

                <h2 className={styles.h2}>目次</h2>
                <div className={styles.tocDesc}>おおよその日程順に並べています。</div>
                <ul className={styles.tocUl}>
                  {taikaiList.map(({ node }) => {
                    const text = `${node.namePrefix || ""}${node.nameOfficial || ""}${node.nameSuffix || ""}${node.myNameSuffixDefault || ""}`;
                    return (
                      <li className={styles.tocLi} key={node.taikaiId}>
                        <a href={"#" + node.taikaiId}>{text}</a>
                      </li>
                    );
                  })}
                </ul>

                {taikaiList.map(({ node }) => {
                  const taikaiId = node.taikaiId;
                  const entries = entryList.filter(({ node }) => node.taikaiId === taikaiId);

                  const entriesWithObjects: EntryWithObjects[] = entries.map(({ node }) => {
                    const e: EntryWithObjects = {
                      node: node,
                    };
                    e.node.dateObj = createMyDate(node.date, node.isFest);
                    e.node.endDateObj = createMyDate(node.endDate, node.isEndDateFest);
                    e.node.openTimeObj = createTime(node.openTime);
                    e.node.startTimeObj = createTime(node.startTime);
                    e.node.endTimeObj = createTime(node.endTime);
                    return e;
                  });

                  const title = `${node.namePrefix || ""}${node.nameOfficial || ""}${node.nameSuffix || ""}`;
                  return (
                    <div key={node.taikaiId}>
                      <h2 id={node.taikaiId ?? ""} className={styles.h2}>
                        {title}
                        {node.myNameSuffixDefault ? <span className={styles.h2MySuffix}>{node.myNameSuffixDefault}</span> : ""}
                      </h2>
                      <div className={styles.itemBlock}>
                        <h3 className={styles.h3}>直近の開催日程</h3>
                        <div className={styles.tableDiv}>
                          <RecentTable data={entriesWithObjects} isClassic={true} />
                        </div>
                        {node.noteDefault
                          ? (
                            <>
                              <h3 className={styles.h3}>備考</h3>
                              <div className={styles.paragraph}>{node.noteDefault}</div>
                            </>
                          )
                          : ""}
                      </div>
                    </div>
                  );
                })}

                <h2 className={styles.footerH2}>その他</h2>
                <div className={styles.footer}>
                  <p className={styles.p}>
                    このサイトへのリンクはご自由にどうぞ。何かありましたらサイト製作者のやのけん (yanoken850p アットマーク gmail.com) まで。<br />
                    サイトについて詳しくは <Link to="/about/">こちらのページ</Link> もご覧ください。<br />
                    このページはGoogleドライブ上に保存されたスプレッドシートからGatsby.jsによって半自動的に生成されております。 (^^;
                  </p>
                </div>
              </div>
            </div>
          </Wrapper>
        </Main>
      </>
    </>
  );
};

export default OldHome;

export const query = graphql`
  query OldHome {
    allGoogleMetaSheet(sort: {fields: date, order: ASC}, filter: {nameOfficial: {regex: "/^[^#?]/"}}) {
      edges {
        node {
          taikaiId
          outdated
          namePrefix
          nameOfficial
          nameSuffix
          myNameSuffixDefault
          date
          noteDefault
        }
      }
    }
    allGoogleDataSheet(filter: {name: {regex: "/^[^#?]/"}}) {
      edges {
        node {
          taikaiId
          namePrefix
          name
          nameSuffix
          myNameSuffix
          canceled
          date
          isFest
          endDate
          isEndDateFest
          openTime
          startTime
          endTime
          locationName
          locationSubName
          note
          sources
        }
      }
    }
    allGoogleHistorySheet {
      edges {
        node {
          updateDate
          isNew
          taikaiId
          title
          startDate
          endDate
        }
      }
    }
  }
`;
