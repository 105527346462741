// extracted by mini-css-extract-plugin
export var footer = "index2-module--footer--13615";
export var footerH2 = "index2-module--footerH2--f4359";
export var h2 = "index2-module--h2--99f0f";
export var h2MySuffix = "index2-module--h2MySuffix--864da";
export var h3 = "index2-module--h3--ef45e";
export var itemBlock = "index2-module--itemBlock--e7bdf";
export var leftPane = "index2-module--leftPane--6f4ed";
export var main = "index2-module--main--16916";
export var menuBox = "index2-module--menuBox--285a8";
export var menuContentBox = "index2-module--menuContentBox--ed508";
export var menuLi = "index2-module--menuLi--0e179";
export var menuSelectedLi = "index2-module--menuSelectedLi--3dbdb";
export var menuTitle = "index2-module--menuTitle--47217";
export var menuUl = "index2-module--menuUl--4a9e1";
export var noteH2 = "index2-module--noteH2--76659";
export var noteLi = "index2-module--noteLi--6e6b9";
export var noteUl = "index2-module--noteUl--dd4bb";
export var noticeBox = "index2-module--noticeBox--6d6ea";
export var p = "index2-module--p--d5fa7";
export var paragraph = "index2-module--paragraph--c7bea";
export var tableDiv = "index2-module--tableDiv--e1772";
export var tocDesc = "index2-module--tocDesc--0b7bb";
export var tocLi = "index2-module--tocLi--537ab";
export var tocUl = "index2-module--tocUl--6f41e";
export var wrapper = "index2-module--wrapper--f580d";